import type { PerformanceAttributeId, PerformanceAttributeValue } from 'types/product';

export const HIGHLIGHTS_ACCORDION_HEADING = 'Highlights';
export const SIZE_CHART_ACCORDION_HEADING = 'Size Chart';
export const TECH_SPECS_ACCORDION_HEADING = 'Tech Specs';

export const WEIGHT_ATTRIBUTE = 90; // Shoe Weight
export const SUPPORT_ATTRIBUTE = 100; // Performance Shoe Support Type
export const BEST_FOR_ATTRIBUTE = 101; // Performance
export const CUSHIONING_ATTRIBUTE = 182; // Running Footwear Cushioning
export const SHOP_ATTRIBUTE = 303;

export const KIDS_SHOP_ID = 6102;

export const PERFORMANCE_ATTRIBUTES = [BEST_FOR_ATTRIBUTE, CUSHIONING_ATTRIBUTE, SUPPORT_ATTRIBUTE, WEIGHT_ATTRIBUTE];

export const RUNNING_ATTRIBUTE_VALUES = ['Running', 'Trail Running'];

export const BEST_FOR_ICON_URL = 'https://m.media-amazon.com/images/G/01/zappos/ui/running._CB1739985277_.svg';
export const CUSHIONING_ICON_URL = 'https://m.media-amazon.com/images/G/01/zappos/ui/cushioning._CB1739985078_.svg';
export const HEEL_ICON_URL = 'https://m.media-amazon.com/images/G/01/zappos/ui/heel._CB1740064838_.svg';
export const STABILITY_ICON_URL = 'https://m.media-amazon.com/images/G/01/zappos/ui/stability._CB1740065033_.svg';
export const SUPPORT_ICON_URL = 'https://m.media-amazon.com/images/G/01/zappos/ui/support._CB1739985996_.svg';
export const WEIGHT_ICON_URL = 'https://m.media-amazon.com/images/G/01/zappos/ui/weight._CB1739984887_.svg';

export const PERFORMANCE_CATEGORIES = ['Sneakers & Athletic Shoes'];

export const PERFORMANCE_DISPLAY_MAP: Record<PerformanceAttributeId, PerformanceAttributeValue> = {
  [BEST_FOR_ATTRIBUTE]: {
    icons: {
      default: BEST_FOR_ICON_URL
    },
    title: 'Best For'
  },
  [CUSHIONING_ATTRIBUTE]: {
    icons: {
      default: CUSHIONING_ICON_URL,
      Stability: STABILITY_ICON_URL
    },
    title: 'Cushioning'
  },
  [SUPPORT_ATTRIBUTE]: {
    icons: {
      default: SUPPORT_ICON_URL
    },
    title: 'Support Type'
  },
  [WEIGHT_ATTRIBUTE]: { icons: { default: WEIGHT_ICON_URL }, title: 'Weight' }
} as const;
